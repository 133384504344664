import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Box,
  Image,
  Heading,
  Text,
  Link as ExternalLink,
  Button,
} from 'theme-ui';
import Slider from 'react-slick';
import { GrGithub, GrInstagram, GrLinkedin } from 'react-icons/gr';
import { FaCaretDown } from 'react-icons/fa';
export default function ProjectsContainer() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulPortfolio(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            projectName
            projectDescription {
              childrenMarkdownRemark {
                html
              }
            }
            projectSubtitle
            smallDescription
            projectType
            colorScheme
            projectBanner {
              file {
                url
              }
            }
            projectUrl
            githubRepoUrl
            techStack {
              id
              website
              name
              logo {
                file {
                  url
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  const portfolioList = data.allContentfulPortfolio.edges;

  const [portfolioListOpen, setPortfolioListOpen] = useState(false);
  const myRefs = useRef([]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.projectListBar}>
        <Box sx={styles.listHeaderContainer}>
          <Heading as='h3' sx={styles.listTitle}>
            Portfolio
          </Heading>

          <Text
            as='p'
            sx={styles.projectSelector}
            onClick={() => {
              setPortfolioListOpen(!portfolioListOpen);
            }}
          >
            Select Project <FaCaretDown />
          </Text>
        </Box>
        <Box
          sx={{
            ...styles.portfolioListContainer,
            ...(portfolioListOpen
              ? { ...styles.portfolioListContainerOpen }
              : {}),
            // {...portfolioListOpen
            //   ? styles.portfolioListContainerOpen
            //   :{}}
          }}
        >
          {portfolioList.map(({ node }, index) => {
            return (
              <Button
                variant='buttons.textOnly'
                sx={styles.projectListButton}
                onClick={() => {
                  setPortfolioListOpen(false);
                  myRefs.current[index].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                  });
                }}
              >
                {node.projectName}
              </Button>
            );
          })}
        </Box>
        <Box sx={styles.socialIcons} className='socialIcons'>
          <ExternalLink
            href='https://www.github.com/stefantrinh1'
            target='_blank'
            rel='noopener'
          >
            <GrGithub />
          </ExternalLink>
          <ExternalLink
            href='https://www.linkedin.com/in/stefantrinh1/'
            target='_blank'
            rel='noopener'
          >
            <GrLinkedin />
          </ExternalLink>
          <ExternalLink
            href='https://www.instagram.com/stefan.codes/'
            target='_blank'
            rel='noopener'
          >
            <GrInstagram />
          </ExternalLink>
        </Box>
      </Box>
      <Box sx={styles.projectsContainer}>
        {portfolioList.map(({ node }, index) => {
          const {
            id,
            projectName,
            projectDescription: { projectDescription },
            projectSubtitle,
            smallDescription,
            projectType,
            projectUrl,
            colorScheme,
            techStack,
            projectBanner: {
              file: { url: mainImageUrl },
            },
          } = node;
          console.log(techStack);
          //    top: ['60px', '', '86px', 'unset'],

          return (
            <Box sx={styles.project} ref={(el) => (myRefs.current[index] = el)}>
              <Box sx={styles.imageContainer}>
                <Image src={mainImageUrl} />
              </Box>
              <Box sx={styles.textContainer}>
                <Heading sx={styles.title}>{projectName}</Heading>

                <Text as='p' sx={styles.type}>
                  {projectType}
                </Text>
                <Heading sx={styles.subtitle}>{projectSubtitle}</Heading>

                <Text as='p' sx={styles.description}>
                  {smallDescription}
                </Text>

                <Heading sx={styles.sectionHeading}>The Palette</Heading>

                <Box sx={styles.colorBoxes}>
                  {colorScheme.map((color) => {
                    return (
                      <Box
                        sx={{
                          ...styles.colorBox,
                          backgroundColor: color,
                        }}
                      />
                    );
                  })}
                </Box>

                <Heading sx={styles.sectionHeading}>The Stack</Heading>
                <Slider {...settings}>
                  {techStack.map((node) => {
                    return (
                      <ExternalLink
                        href={node.logo.website}
                        target='_blank'
                        key={node.id}
                      >
                        <Image
                          sx={styles.logo}
                          src={node.logo.file.url}
                          alt={`${node.logo.title}logo`}
                        />
                      </ExternalLink>
                    );
                  })}
                </Slider>

                <Box sx={styles.ctaContainer}>
                  {projectUrl ? (
                    <ExternalLink target='_blank' href={projectUrl}>
                      Go To Project
                    </ExternalLink>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    padding: ['0rem 1rem', '', '1rem', '0rem 1rem'],
    width: '100%',
    // height: '100vh',
    display: 'flex',
    flexDirection: ['column', '', '', 'row'],
  },

  listHeaderContainer: {
    display: 'flex',
    width: '100%',
  },
  listTitle: {
    color: 'primary',
    marginBottom: ['', '', '', '1rem'],
    backgroundColor: ['black', '', '', 'transparent'],
    padding: ['1rem', '', '', '0rem'],
    display: 'flex',
    alignItems: 'center',
  },
  projectSelector: {
    display: ['flex', '', '', 'none'],
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: '1',
    cursor: 'pointer',
    backgroundColor: 'primary',
    color: 'white',
  },
  projectListBar: {
    padding: ['0rem', '', '', '1rem'],
    backgroundColor: 'white',
    zIndex: '100',
    flexDirection: ['column', '', '', 'column'],
    width: ['100%', '', '', '20%'],
    borderRight: 'solid 1px lightgrey',
    height: '100%',
    position: ['fixed', '', '', 'sticky'],
    top: ['60px', '', '86px', '86px'],
    left: '0rem',
    height: ['', '', '', 'calc(100vh - 86px)'],
    flexGrow: '1',
    display: 'flex',
    flexWrap: 'wrap',
  },
  portfolioListContainer: {
    // display: ['none', '', '', 'flex'],
    flexDirection: 'column',
    transition: 'all ease-in-out 0.5s',
    top: '54px',
    left: '-100rem',
    position: ['absolute', '', '', 'static'],
    width: '100%',
    borderBottom: ['solid 1px lightgrey', '', '', 'none'],
    backgroundColor: ['#fff6f6', '', '', 'transparent'],
  },

  portfolioListContainerOpen: {
    // display: ['flex', '', '', ''],
    left: '0rem',
    top: '54px',
  },

  projectListButton: {
    fontSize: '0.9rem',
    // opacity: '0.8',
    padding: ['1rem', '', '', '1rem 0rem'],
    flexGrow: '1',
    width: '100%',

    borderRadius: '0px',
    ':hover': {
      color: 'primary',
      opacity: '1',
    },
  },

  socialIcons: {
    display: ['none', '', '', 'flex'],
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 'auto 0 0',
    svg: {
      color: 'primary',
      margin: '0.5rem 0.5rem 0.5rem 0rem',
      height: '25px',
      width: '25px',
    },
  },

  projectsContainer: {
    minHeight: '100vh',
    width: ['100%', '', '', '80%'],
    // marginLeft: ['', '', '', 'calc(20% + 0rem)'],
    padding: ['0rem', '', '', '1rem'],
  },

  project: {
    width: '100%',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    flexWrap: 'wrap',
    margin: '0rem 0rem 3rem',
    // justifyContent:'center',
    // alignItems:'center'
  },
  imageContainer: {
    width: ['100%', '', '50%'],
    minHeight: '40vh',
    img: {
      height: '100%',
      width: ['auto', '100%', '100%'],
      objectFit: 'cover',
      backgroundColor: '#f0eded',
      borderRadius: '10px',
    },
  },
  textContainer: {
    width: ['100%', '', '50%'],
    padding: ['1rem 0rem', '', '1rem', '2rem 2rem'],
  },
  title: {
    fontSize: '1.25rem',
    marginBottom: '0.5rem',
    color: 'primary',
  },
  subtitle: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
    coilor: 'brandGreen',
  },
  sectionHeading: {
    color: 'grey',
    fontSize: '0.9rem',
    margin: '1.5rem 0rem 1rem',
  },
  colorBoxes: {
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr 1fr',
    // gridTemplateRows: '1fr 1fr',
    // gap: '0px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1rem 0rem',
  },
  colorBox: {
    width: '20px',
    position: 'relative',
    height: '20px',
    borderRadius: '500px',
    marginRight: '1rem',
    border: 'solid 1px white',
    transition: 'all ease-in-out 0.3s',
    // cursor: 'pointer',
    '::before': {
      content: "''",
      width: '25px',
      height: '25px',
      border: 'solid 1px lightgrey',
      borderRadius: '500px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
    },
    // ':hover': {
    //   transform: 'scale(1.1)',
    // },
    // maxWidth: '33.33%',
  },

  type: {
    opacity: '0.6',
    fontSize: '0.8rem',
    marginBottom: '0.75rem',
    color: 'grey',
  },
  description: { fontSize: '0.75rem' },
  logo: {
    height: '25px',
    margin: '0.5rem',
  },

  ctaContainer: {
    display: 'flex',
    margin: '1rem 0rem',
    paddingTop: '1rem',
    borderTop: '1px solid lightgrey',

    'a:nth-of-type(1)': {
      variant: 'buttons.primary',
      marginRight: '1rem',
      fontSize: '0.9rem',
      padding: '0.5rem',
    },
    'a:nth-of-type(2)': {
      variant: 'buttons.secondary',
      fontSize: '0.9rem',
      padding: '0.5rem',
    },
  },
};

const settings = {
  dots: false,
  fade: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 2000,
  autoplay: true,
  slidesToScroll: 2,
  variableWidth: true,
};
