import React from 'react'
import { Box, Image, Heading, Text } from 'theme-ui'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function ProjectsSideBar() {
  return (
    <Box sx={styles.container}>
      <Heading as='h2' sx={styles.subtitle}>
        Stefan Trinh
      </Heading>
      <Heading as='h1' sx={styles.title}>
        Portfolio
      </Heading>
      <Box sx={styles.imageContainer}>
        <Image src='https://res.cloudinary.com/dpakxm3nm/image/upload/portfolio/projectsabout.jpg' />
      </Box>

      <Text as='p' sx={styles.text}>
        The final product is the goal. Making software products that are fast,
        reliable and visionally stunning.
      </Text>
      <Box sx={styles.buttonContainer}>
        <AniLink
          to='/contact'
          rel='noopener'
          paintDrip
          duration={0}
          hex='#e01f5a'
        >
          Get In Touch
        </AniLink>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    height: 'calc(100vh - 87px - 51px - 1rem)',
    overflow: 'hidden',
    position: 'relative',
    padding: '1rem',
    borderRight: '1px solid lightgrey',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '2rem',
    fontWeight: '400',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    coilor: 'black',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: '400',
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },

  imageContainer: {
    paddingRight: '1rem',
    marginBottom: '1rem',
    img: {
      maxHeight: '30vh',
      objectFit: 'cover',
      width: '100%',
    },
  },

  text: {
    fontSize: '0.8rem',
    lineHeight: '1.75',
    opacity: '0.7',
    marginBottom: '2rem',
  },
  buttonContainer: {
    a: {
      variant: 'buttons.secondary',
    },
  },
}
