import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Box, Image, Heading, Text, Link as ExternalLink } from 'theme-ui';
import ProjectsSideBar from '../components/ProjectsSections/ProjectsSideBar';
import ProjectsContainer from '../components/ProjectsSections/ProjectsContainer';
export default function ProjectsMenuTemplate({ data }) {
  console.log(data);
  return (
    <Box sx={styles.container}>
      {/* <Box sx={styles.sideBarContainer}>
        <ProjectsSideBar />
      </Box> */}
      <Box sx={styles.projectsContainer}>
        <ProjectsContainer />
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: ['8rem', '', '', '86px'],
  },

  sideBarContainer: {
    width: ['100%', '', '', '20%'],
    order: ['2', '', '', 'unset'],
    position: ['', '', '', 'fixed'],
  },

  projectsContainer: {
    // marginLeft: ['', '', '', '20%'],
    width: ['100%', '', '', '100%'],
  },
};

// import React from 'react'
// import { graphql } from 'gatsby'
// import { Box, Heading } from 'theme-ui'
// import PageHero from '../components/Hero/PageHero'
// import ArticlesBoxes from '../components/Articles/ArticlesBoxes'
// import Pagination from 'rc-pagination'
// import SocialIconsBox from '../components/UIElements/SocialIconsBox'
// import FunFacts from '../components/UIElements/FunFacts'
// import Fade from 'react-reveal/Fade'
// import DownloadTheApp from '../components/UIElements/DownloadTheApp'

// export default function BecomeAWineConnoisseur({
//   data,
//   pageContext,
//   path,
//   ...props
// }) {
//   const articleTypeKey = () => {
//     switch (pageContext.articleType) {
//       case 'featuredInWineArticles':
//         return 'allContentfulTrendingBlogs'

//       case 'wineConnoisseurArticles':
//         return 'allContentfulConnoisseurBlogs'

//       default:
//         return ''
//     }
//   }

//   const articlesData = data[articleTypeKey()].nodes

//   return (
//     <Box sx={styles.container}>
//       <PageHero
//         title={pageContext.pageTitle}
//         subtitle={pageContext.pageSubtitle}
//         text={pageContext.text}
//         image={pageContext.image}
//         imageDescription={pageContext.imageDescription}
//       />

//       <Box sx={styles.row}>
//         <Box sx={styles.articlesColumn}>
//           <Fade left cascade>
//             <Heading as='h3' sx={styles.title}>
//               Keep Up To Date
//             </Heading>
//             <Heading as='h4' sx={styles.subtitle}>
//               Read All About It
//             </Heading>

//             <ArticlesBoxes
//               articlesData={articlesData}
//               path={path}
//               style={styles.articleBoxes}
//             />
//           </Fade>

//           {/* <Pagination
//             current={pageContext.currentPage}
//             defaultCurrent={1}
//             total={pageContext.limit * pageContext.NumOfPages}
//             defaultPageSize={10}
//           /> */}
//         </Box>
//         <Box sx={styles.widgetColumn}>
//           <Fade right cascade>
//             <FunFacts style={styles.funFacts} />
//             <DownloadTheApp style={styles.socialIconsBox} />
//             <SocialIconsBox style={styles.socialIconsBox} />
//           </Fade>
//         </Box>
//       </Box>
//     </Box>
//   )
// }

// const styles = {
//   container: {},
//   row: {
//     display: 'flex',
//     flexDirection: ['column', '', 'row'],
//     width: '100%',
//     // maxWidth: '1200px',
//     margin: '0 auto',
//     paddingLeft: ['0rem', '', 'calc(3% - 0.5rem)'],
//   },
//   articlesColumn: {
//     width: ['100%', '', '65%', '70%', '70%'],
//   },
//   widgetColumn: {
//     width: ['100%', '', '35%', '30%', '30%'],
//   },
//   title: {
//     padding: '0rem 1rem 1rem',
//     fontSize: '3rem',
//     color: 'white',
//   },
//   subtitle: {
//     padding: '0rem 1rem 1rem',
//     fontSize: '1.5rem',
//     color: 'primary',
//   },
//   articleBoxes: {
//     maxWidth: 'unset',
//     paddingRight: ['', '', '', '2rem', '4rem'],
//   },
//   funFacts: {
//     maxWidth: 'unset',
//     width: [
//       'calc(100% - 1rem)',
//       '',
//       '',
//       'calc(100% - 2rem)',
//       'calc(100% - 4rem)',
//     ],
//     margin: [
//       '2rem 0.5rem 1rem',
//       '',
//       '0rem 0.5rem 1rem',
//       '0rem 1rem 1rem',
//       '0rem 2rem 2rem',
//     ],
//   },

//   socialIconsBox: {
//     width: [
//       'calc(100% - 1rem)',
//       '',
//       '',
//       'calc(100% - 2rem)',
//       'calc(100% - 4rem)',
//     ],
//     margin: [
//       '2rem 0.5rem 1rem',
//       '',
//       '0rem 0.5rem 1rem',
//       '0rem 1rem 1rem',
//       '0rem 2rem 2rem',
//     ],
//   },
// }

// export const query = graphql`
//   query ArticleMenuTemplateQuery($skip: Int!, $limit: Int!) {
//     allContentfulTrendingBlogs(
//       sort: { fields: updatedAt, order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         id
//         title
//         subtitle
//         description {
//           raw
//         }
//         content {
//           raw
//         }
//         image {
//           description
//           fluid {
//             ...GatsbyContentfulFluid_tracedSVG
//           }
//         }
//         createdAt(formatString: "DD MMM YYYY")
//         updatedAt(formatString: "DD MMM YYYY")
//       }
//     }
//     allContentfulConnoisseurBlogs(
//       sort: { fields: updatedAt, order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         id
//         title
//         subtitle

//         description {
//           raw
//         }
//         content {
//           raw
//         }
//         image {
//           description
//           fluid {
//             ...GatsbyContentfulFluid_tracedSVG
//           }
//         }
//         updatedAt(formatString: "DD MMM YYYY")
//         createdAt(formatString: "DD MMM YYYY")
//       }
//     }
//   }
// `
